import { Grid,  Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { LinkButton } from "../../library-components/Buttons/Buttons";
import { GlobalContext } from "../../../context/GlobalContext";
import {
  computeService,
  computeCampaign,
} from "../../../API/graphql-queries/Queries";
import { ComputeServiceDataResponse } from "../../../interfaces-and-unionTypes/Interfaces";
import { useLazyQuery } from "react-apollo";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import InfoDialog from "../../library-components/Dialogs/InfoDialog/InfoDialog";
import { NetworkStatus } from "apollo-boost";
import ProgressCarLoading from "../ProgressBar/ProgressCarLoading";
import ContinueButton from "./ContinueButton";
import { useStylesBasketContent } from "../Styles/SummaryStyles";
type BasketStylePropType = {
  isCheckout: boolean;
};
import CampaignCodeDialog from "../../library-components/Dialogs/CampaignCodeDialog";
import { splitUserInfo } from "../../../Helpers/stringHelpers";
import Basket2 from "../basket2/Basket2";
import { getFormattedTotalPrice, canTotalPriceBeCalculated } from "../../../services/price.service";
import { StateType } from "../../../context/Types";

const BasketContent: React.FC = () => {
  const props: BasketStylePropType = {
    isCheckout: window.location.pathname.includes("checkout"),
  };
  const { state, dispatch } = useContext(GlobalContext);

  const { t } = useTranslation();
  const query = useLocation();

  const history = useHistory();

  const basket2 = Basket2({});

  const handleTooltipClose = () => {
    setOpen(false);
  };
  const heavyCampaigns = ["fleggaardleasing0"]
  const [openCampaignCodeModal, setOpenCampaignCodeModal] = useState(false);

  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogMin, setOpenDialogMin] = useState(false);

  const classes = useStylesBasketContent(props);

  const [computedService, result] = useLazyQuery<
    Partial<ComputeServiceDataResponse>
  >(computeService, {
    onCompleted: (data) =>
      dispatch({ type: "ADD_TO_BASKET", payload: data.computeService?.node }),
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (result?.data === null) {
      alert(result?.error?.message);
    } else {
      if (result?.data?.computeService?.node === null) {
        alert(result?.data?.computeService?.message);
      }
      if (result?.data?.computeService?.message === "Session expired") {
        alert("Session expired");
        history.push("/");
      }
    }
  }, [result?.data]);

  const [computedCampaign, res] = useLazyQuery<
    Partial<ComputeServiceDataResponse>
  >(computeCampaign, {
    onCompleted: (data) =>
      dispatch({ type: "ADD_TO_BASKET", payload: data.computeCampaign?.node }),
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (res?.data === null) {
      alert(res?.error?.message);
    } else {
      if (res?.data?.computeCampaign?.node === null) {
        alert(res?.data?.computeCampaign?.message);
      }
      if (res?.data?.computeCampaign?.message === "Session expired") {
        alert("Session expired");
        history.push("/");
      }
    }
  }, [res?.data]);

  const handleRemoveClick = (serviceId?: string) => {
    computedService({
      variables: {
        is_selected: 0,
        service_id: serviceId,
        ticket_id: state.ticket?.node.ticket_id || "",
        milage: "0",
        tyre_data: null,
      },
    });
    dispatch({
      type: "REMOVE_FROM_BASKET",
      payload: { serviceId, ...result?.data?.computeService?.node },
    });

    dispatch({
      type: "REMOVE_SERVICE_FROM_BASKET",
      payload: serviceId,
    });
  };

  const dialogParagraphs = [
    { 0: t("whatsThisDialogFirstParagraph") },
    { 1: t("whatsThisDialogSecondParagraph") },
    { 2: t("whatsThisDialogThirdParagraph") },
  ];

  function getBasketPriceText(state: StateType) : string {
    let headerText = "0 DKK";
    
    const itemsAdded = state.basketData && !!state.basketData.service_details
    if (!itemsAdded) 
      return headerText;

    if(canTotalPriceBeCalculated(state))
        headerText = getFormattedTotalPrice(state.basketData?.service_details) + " DKK";
    else
        headerText = t("offerPriceAlternativeText")

    return headerText;
  }

  return (
    <div>
      {
        <div>
          {state.basketData !== null || state.basketData !== undefined ? (
            <div>
              <div>
                {!heavyCampaigns.includes(state.basketData?.campaign_code) ? <>
                    {window.location.pathname.includes("checkout") ? (
                      <div className={classes.headerPrice}>
                        <span>
                          {
                            canTotalPriceBeCalculated(state) ?
                            <Typography className={classes.price}>
                              {t("header3")}
                            </Typography>
                            :
                            <Typography className={classes.price}>
                              {t("headerFromPrice")}
                            </Typography>
                          }
                        </span>
                        <span>
                          {
                            canTotalPriceBeCalculated(state) &&
                            <Typography className={classes.price}>
                            {state.basketData?.total_price?.trim() !== undefined
                              ? state.basketData?.total_price + " DKK"
                              : "0 DKK"}
                          </Typography>
                            }
                        </span>
                      </div>
                    ) : (
                      <div className={classes.header}>
                        <Typography className={classes.price}>
                          { getBasketPriceText(state) }
                        </Typography>
                      </div>
                    )}

                </> :
                  <>
                    <div className={classes.headerPrice}>
                      <span>
                        <Typography className={classes.price}>
                          Fleggaard Leasing
                        </Typography>
                      </span>
                    </div>
                  </>}
                <div className={classes.userInfo}>
                  <Typography className={classes.userInfoText}>
                    {state.ticket?.node.make +
                      " - " +
                      state.ticket?.node.model +
                      " - " +
                      state.ticket?.node.year +
                      " - " +
                      state.ticket?.node.motortype}
                  </Typography>
                </div>
                <div className={classes.userInfo}>
                  {state?.basketData?.location_text ? (
                    <Typography className={classes.userInfoText}>
                      {splitUserInfo(state?.basketData?.location_text)}
                    </Typography>
                  ) : (
                    <Typography className={classes.userInfoText}>
                      {state.ticket?.node?.zip_code +
                        " - " +
                        state.ticket?.node.city +
                        " - " +
                        state.ticket?.node.country}
                    </Typography>
                  )}
                </div>
                <div className={`${classes.userInfo} ${classes.link}`}>
                  <LinkButton
                    classes={classes.linkText}
                    onClick={() => history.push(`/?clear=1`)}
                  >
                    {t("editLink")}
                  </LinkButton>
                  {!heavyCampaigns.includes(state.basketData?.campaign_code) && <> {!window.location.pathname.includes("checkout") ? (
                        <LinkButton
                          style={{
                            fontWeight: "bold",
                            color: "#5F58FE",
                            textDecorationColor: "#5F58FE",
                            fontSize: 18,
                            marginTop: "20px",
                          }}
                          onClick={() => setOpenCampaignCodeModal(true)}
                        >
                          {t("campaignCodeLink")}
                        </LinkButton>
                      ) : null}
                  </>}
                </div>
              </div>

              {!window.location.href.includes("checkout") ? (
                <div>
                  {state.basketData &&
                  state.basketData?.service_details &&
                  state.basketData?.service_details.length >= 4 ? (
                    <div
                      className={`${classes.basketBorder} ${classes.basketShadow}`}
                    ></div>
                  ) : (
                    <div className={classes.basketBorder}></div>
                  )}
                </div>
              ) : null}

              {result.networkStatus !== NetworkStatus.loading ? (
                basket2
              ) : (
                <Grid
                  item
                  container
                  md={12}
                  className={classes.progressCarLoading}
                >
                  <ProgressCarLoading />
                </Grid>
              )}
            </div>
          ) : (
            <></>
          )}
          {!window.location.href.includes("checkout") ? (
            <div>
              {state.basketData &&
              state.basketData?.service_details &&
              state.basketData?.service_details.length >= 4 ? (
                <div className={classes.topBorderWrapper}>
                  <div className={classes.topBorder}></div>
                </div>
              ) : null}
            </div>
          ) : null}
          {!window.location.href.includes("checkout") &&
          state.basketData &&
          state.basketData?.service_details &&
          state.basketData?.service_details.length >= 1 &&
            parseInt(state.basketData?.total_price?.replace(".", "")) < parseInt(state.basketData?.min_price?.replace(".", "")) &&
          state.basketData?.root_missing !== 1 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "24vw",
                height: "6vh",
                paddingTop: "3%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  width: "100%",
                  height: "6vh",
                  paddingLeft: "3%",
                  background: "#e3edbb",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Typography
                    fontSize={12}
                    fontWeight={600}
                    fontFamily={"Poppins, SemiBold"}
                  >
                    {t("minPriceDialogTitle")}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    width: "100%",
                    paddingRight: "3%",
                  }}
                >
                  <LinkButton
                    style={{
                      color: "#5F58FE",
                      textDecorationColor: "#5F58FE",
                    }}
                    onClick={() => setOpenDialogMin(!openDialogMin)}
                  >
                    <Typography fontSize={12}>{t("whatsThisLink")}</Typography>
                  </LinkButton>
                </div>
              </div>
            </div>
          ) : 
          <div className={classes.header}>
          {state.basketData?.fee_amount && !state.returningCustomer.node.fleet_manager_for?.id && (
            <p className="basket-vat-text">
              { t("additionalFeesContactDetails") }
            </p>
          )}
        </div> }
          {!heavyCampaigns.includes(state.basketData?.campaign_code) && <> {res.data?.computeCampaign?.node?.campaign_code ||
              state.basketData?.campaign_code ? (
                <div>
                  {state.basketData?.total_discount !== "0.00" ? (
                    <div
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      <Grid item md={12} fontFamily={"Poppins"} fontSize={14}>
                        <Typography fontFamily={"Poppins"} fontSize={14}>
                          {t("youAppliedCode")}{" "}
                          <span style={{ fontFamily: "Poppins-SemiBold" }}>
                            {state.basketData?.campaign_code}
                          </span>
                          <span>
                            <span style={{ fontSize: "14" }}>
                              {t("withDiscount")}
                            </span>
                            <span
                              style={{
                                fontSize: "14",
                                fontFamily: "Poppins-SemiBold",
                              }}
                            >
                              {state.basketData?.total_discount + " DKK. "}
                            </span>
                            <span style={{ fontSize: "14" }}>
                              {t("discountExplanation")}
                            </span>
                          </span>
                        </Typography>
                      </Grid>
                    </div>
                  ) : (
                    <div
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      <Grid item md={12} fontFamily={"Poppins"} fontSize={14}>
                        <Typography fontFamily={"Poppins"} fontSize={14}>
                        {t("campaignCode")}
                          {": "}
                          <span style={{ fontFamily: "Poppins-SemiBold" }}>
                            {state.basketData?.campaign_code}
                          </span>
                          {state.basketData?.total_discount?.length &&
                          state.basketData?.service_details?.length ? (
                            <span>
                              <span style={{ fontSize: "14" }}>
                                {t("withDiscountPriceZero")}
                              </span>
                              <span
                                style={{
                                  fontSize: "14",
                                  fontFamily: "Poppins-SemiBold",
                                }}
                              >
                                {state.basketData?.total_discount + " DKK"}
                              </span>
                              <span style={{ fontSize: "14" }}>
                                {t("discountExplanationPriceZero")}
                              </span>
                            </span>
                          ) : (
                            ""
                          )}
                        </Typography>
                      </Grid>
                    </div>
                  )}
                </div>
          ) : null}</>}
          <ContinueButton />
        </div>
      }
      <InfoDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        title={t("whatsThisDialogTitle")}
        content={
          <>
            <div className={classes.whatsThisDialog}>
              {dialogParagraphs.map((item: any, index: number) => (
                <div
                  key={index}
                  style={{
                    paddingTop: index === 0 ? "3%" : "5%",
                  }}
                >
                  <Typography className={classes.whatsThisDialogText}>
                    {item[index]}
                  </Typography>
                </div>
              ))}
            </div>
          </>
        }
      />
      <InfoDialog
        open={openDialogMin}
        onClose={() => setOpenDialogMin(false)}
        title={t("minPriceDialogTitle")}
        content={
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  paddingTop: "3%",
                }}
              >
                <Typography
                  fontSize={14}
                  fontWeight={"regular"}
                  textAlign="left"
                >
                  {`Minimumskøb på ${parseInt(state.basketData?.min_price?.replace(".", ""))} DKK for online booking er ikke opnået. Fortsæt venligst din booking og vi kontakter dig indenfor 24 timer på hverdage.`}
                </Typography>
              </div>
            </div>
          </>
        }
      />
      <CampaignCodeDialog
        open={openCampaignCodeModal}
        close={() => {
          setOpenCampaignCodeModal(false);
        }}
        save={computedCampaign}
      />
    </div >
  );
};

export default BasketContent;
